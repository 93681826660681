import {useEffect, useState} from "react";
import CodeReader from "./CodeReader";
import axios from "axios";
import config from "./config/config";
import './style.scss';
import gfx_map from './map.jpg';
import gfx_bg from './bg.jpg';
import gfx_wristband from './wristband.svg';
import gfx_location from './location.svg';
import moment from "moment";
import "moment/locale/de";

const waitingSpot = {
  mic: "Mikro",
  island: "Insel",
  keys: "Schlüssel",
  kitchen: "Küche",
  star: "Stern",
  mobile: "Mobile Zellen",
  bike: "Fahrrad",
  sofa: "Sofa",
  tent: "Zelt",
  sun: "Sonne",
  tree: "Baum",
  window: "bitte erfragem",
  turtle: "Taube",
  bottle: "Flasche"
};

function App() {

  const [currentStatus, setCurrentStatus] = useState('DEFAULT');
  const [shifts, setShifts] = useState(null);

  useEffect(() => {
    window.setTimeout(() => {
      if (currentStatus === 'DEFAULT') {
        window.location.reload(true);
      }
    }, 600000);
  }, []);

  const handleScan = (code) => {
    setCurrentStatus('LOADING');
    setShifts(null);
    axios.get(`${config.server.endpoint}/self-service/shifts/${code}`)
      .then((response) => {
        if (!response.data.success) {
          setCurrentStatus('ERROR');
          startTimeout(5000);
        } else {
          const data = response.data.data;
          if (!data) {
            setCurrentStatus('NOT_FOUND');
            startTimeout(5000);
          } else {
            setCurrentStatus('SHOW_SHIFTS');
            startTimeout();
            setShifts(data);
          }
        }
      })
      .catch(() => {
        startTimeout(5000);
        setCurrentStatus('ERROR');
      })
  }

  const startTimeout = (time) => {
    if (!time) time = 20000;
    window.setTimeout(() => {
      setCurrentStatus('DEFAULT');
      setShifts(null);
    }, time);
  }

  return (
    <div className="App">
      <CodeReader onScan={handleScan}/>
      <div className={`bg${currentStatus === 'SHOW_SHIFTS' ? ' in' : ''}`} style={{backgroundImage: `url(${gfx_bg})`}}/>
      <img src={gfx_map} alt="" className={`map${currentStatus === 'SHOW_SHIFTS' ? ' in' : ''}`}/>
      <div className={`content-pane${currentStatus === 'SHOW_SHIFTS' ? ' in' : ''}`}>
        <div>
          <h2>
            SUPPORTER:INNEN&nbsp;SCHICHTAUSKUNFT
          </h2>
        </div>
        <div>
          <h4>
            SUPPORTER SHIFTINFORMATION
          </h4>
        </div>
        <div className={`instruction${currentStatus === 'SHOW_SHIFTS' ? ' out' : ''}`}>
          {currentStatus === 'NOT_FOUND' ? (
            <div className="error-message">
              BÄNDSEL&nbsp;NICHT&nbsp;GEFUNDEN<br/>
              WRITSBAND NOT FOUND
            </div>
          ) : null}
          {currentStatus === 'ERROR' ? (
            <div className="error-message">
              SERVERFEHLER!<br/>
              SERVER ERROR!
            </div>
          ) : null}
          <div>
            <h3>
              BITTE HALTE DEIN BÄNDSEL UNTER DEN SCANNER
            </h3>
          </div>
          <div>
            <h5>
              PLEASE HOLD YOUR WRISTBAND UNDER THE SCANNER
            </h5>
          </div>
          <img src={gfx_wristband} alt="" className="wristband"/>
        </div>
        <div className={`shifts${currentStatus === 'SHOW_SHIFTS' ? ' in' : ''}`}>
          <div>
            <h3>
              DEINE SCHICHTEN
            </h3>
          </div>
          <div>
            <h5>
              YOUR SHIFTS
            </h5>
          </div>
          <div className="shifts-list">
            {shifts?.map((shift, sI) => (
              <div className="shift" key={sI}>
                <div className="number">{sI + 1}</div>
                <div className="info">
                  <b>
                    {moment(shift.startAt).subtract(30, 'minute').locale('de').format('ddd')}, {moment(shift.startAt).subtract(30, 'minute').locale('en').format('ddd')}
                  </b>
                  <span className="spacer"/>//<span className="spacer"/>{moment(shift.startAt).subtract(30, 'minute').format('DD.MM.')} - {moment(shift.startAt).subtract(30, 'minute').format('HH:mm')}
                </div>
                {shift.waitingSpot ? (
                  <div className="location">
                    <img src={gfx_location} alt=""/>
                    {waitingSpot[shift.waitingSpot]}
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
