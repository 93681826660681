import React from 'react';
import BarcodeReader from 'react-barcode-reader';

const CodeReader = ({ onScan }) => {
  return (
    <div className="CodeReader">
      <BarcodeReader
        avgTimeByChar={100}
        timeBeforeScanTest={200}
        onError={(err) => console.log('err', err)}
        onScan={(data) => (onScan ? onScan(data) : console.log('data', data))}
      />
    </div>
  );
};

export default CodeReader;